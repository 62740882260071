import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["alt"]

import BaseLink from '@/components_new/BaseLink.vue'
import { LinkTarget } from '@/types'
import 'lazysizes'

interface CardContainerProps {
    is?: 'div' | typeof BaseLink
    to?: string
    appearance: 'big' | 'medium'
    target?: LinkTarget
    src?: string
    alt?: string
    srcsetOn?: boolean
    responsive?: boolean
    lazy?: boolean
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CardContainer',
  props: {
    is: { default: 'div' },
    to: {},
    appearance: {},
    target: {},
    src: {},
    alt: {},
    srcsetOn: { type: Boolean, default: true },
    responsive: { type: Boolean },
    lazy: { type: Boolean, default: true }
  },
  setup(__props: any) {

const props = __props

// TODO bring adaptive sizes back with custom lazysizes plugin https://playgama.atlassian.net/browse/DEV-861

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(props.is), {
    to: props.to,
    target: props.target,
    class: _normalizeClass([
            _ctx.$style.cardContainer,
            props.responsive && _ctx.$style.responsive,
            props.appearance === 'big' && _ctx.$style.bigCard,
        ])
  }, {
    default: _withCtx(() => [
      (props.src)
        ? (_openBlock(), _createElementBlock("img", _mergeProps({
            key: 0,
            "data-allow-mismatch": "",
            alt: props.alt
          }, 
                props.lazy
                    ? {
                          'data-src': props.src,
                          class: [_ctx.$style.img, 'lazyload'],
                      }
                    : {
                          src: props.src,
                          class: _ctx.$style.img,
                      }
            , { itemprop: "image" }), null, 16, _hoisted_1))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["to", "target", "class"]))
}
}

})