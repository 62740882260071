<template>
    <component
        :is="props.is"
        :to="props.to"
        :target="props.target"
        :class="[
            $style.cardContainer,
            props.responsive && $style.responsive,
            props.appearance === 'big' && $style.bigCard,
        ]"
    >
        <img
            v-if="props.src"
            data-allow-mismatch
            :alt="props.alt"
            v-bind="
                props.lazy
                    ? {
                          'data-src': props.src,
                          class: [$style.img, 'lazyload'],
                      }
                    : {
                          src: props.src,
                          class: $style.img,
                      }
            "
            itemprop="image"
        />
        <slot />
    </component>
</template>

<script setup lang="ts">
import BaseLink from '@/components_new/BaseLink.vue'
import { LinkTarget } from '@/types'
import 'lazysizes'

interface CardContainerProps {
    is?: 'div' | typeof BaseLink
    to?: string
    appearance: 'big' | 'medium'
    target?: LinkTarget
    src?: string
    alt?: string
    srcsetOn?: boolean
    responsive?: boolean
    lazy?: boolean
}

const props = withDefaults(defineProps<CardContainerProps>(), {
    is: 'div',
    srcsetOn: true,
    lazy: true,
})

// TODO bring adaptive sizes back with custom lazysizes plugin https://playgama.atlassian.net/browse/DEV-861
</script>

<style module>
.cardContainer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    grid-template-areas: 'main';
    width: 230px;
    aspect-ratio: var(--thumbnail-aspect-ratio);
    border-radius: 12px;
    overflow: hidden;
    background-color: var(--carbon-300);
}
.responsive {
    width: 100%;
    aspect-ratio: var(--thumbnail-aspect-ratio);
    height: auto;
}

.bigCard.responsive {
    --big-card-width: 320;
    --big-card-height: calc(320 / var(--thumbnail-aspect-ratio));

    aspect-ratio: calc(var(--big-card-width) + var(--cards-gap)) / calc(var(--big-card-height) + var(--cards-gap)); /* same as normal, but with additional 8px gaps */
}

.img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    grid-area: main;
}

:global(.lazyload),
:global(.lazyloading) {
    opacity: 0;
}
:global(.lazyloaded) {
    opacity: 1;
    transition: opacity ease-out 0.1s;
}
</style>
